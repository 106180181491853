import React from 'react';

function User({ width }) {
  return (
    <svg width={width || '18'} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.40918C11.2091 1.40918 13 3.20004 13 5.40918C13 7.61832 11.2091 9.40918 9 9.40918C6.79086 9.40918 5 7.61832 5 5.40918C5 3.20004 6.79086 1.40918 9 1.40918ZM9 3.40918C7.89543 3.40918 7 4.30461 7 5.40918C7 6.51375 7.89543 7.40918 9 7.40918C10.1046 7.40918 11 6.51375 11 5.40918C11 4.30461 10.1046 3.40918 9 3.40918ZM9 10.4092C11.67 10.4092 17 11.7392 17 14.4092V17.4092H1V14.4092C1 11.7392 6.33 10.4092 9 10.4092ZM9 12.3092C6.03 12.3092 2.9 13.7692 2.9 14.4092V15.5092H15.1V14.4092C15.1 13.7692 11.97 12.3092 9 12.3092Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default User;
