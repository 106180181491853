import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import BtnMenu from 'theme/buttons/link-menu';

function Btn({ title, style, children, route }) {
  const router = useRouter();

  const isFocused = useMemo(() => {
    const basePath = router.pathname.split('/')[1];

    return route.includes(`/${basePath}`);
  }, [router.pathname, route]);

  return (
    <BtnMenu
      title={title}
      type="button"
      isFocused={isFocused}
      style={{
        width: ['76px', '76px', '76px', '100px'],
        height: ['50px', '50px', '50px', '64px'],
        mb: 'm',
        ...style,
      }}
      href={route[0]}
    >
      {children}
    </BtnMenu>
  );
}

export default Btn;
