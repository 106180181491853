import React from 'react';
import styled from 'styled-components';
import { color, space, typography } from 'styled-system';
import translations from 'translations';

const WrapError = styled.div`
  ${color}
  ${typography}
  ${space}

  display: flex;
  width: 100%;
  min-height: 20px;
  align-items: baseline;
`;

function InputError({ value, meta, languageCode }) {
  return (
    <WrapError fontSize="pSmall" lineHeight="pSmall" fontWeight="normal" color="red_400">
      {meta.error && (meta.touched || value) && (
        <p>{translations[languageCode][meta.error] || meta.error}</p>
      )}
    </WrapError>
  );
}

export default InputError;
