import React from 'react';

function CheckCircle() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M9 0.227051C4.05 0.227051 0 4.27705 0 9.22705C0 14.1771 4.05 18.2271 9 18.2271C13.95 18.2271 18 14.1771 18 9.22705C18 4.27705 13.95 0.227051 9 0.227051ZM9 16.4271C5.031 16.4271 1.8 13.1961 1.8 9.22705C1.8 5.25805 5.031 2.02705 9 2.02705C12.969 2.02705 16.2 5.25805 16.2 9.22705C16.2 13.1961 12.969 16.4271 9 16.4271ZM13.131 5.24905L7.2 11.1801L4.869 8.85805L3.6 10.1271L7.2 13.7271L14.4 6.52705L13.131 5.24905Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0.227051H18V18.2271H0V0.227051Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CheckCircle;
