import React, { useMemo } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

const Wrapper = styled.div`
  ${space}

  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  right: 0;
  padding-top: ${themeGet('space.xs')}px;
  z-index: 999;

  ${({ position }) => ({ ...position })}

  & > ul {
    width: fit-content;
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
      0px 10px 20px rgba(0, 0, 0, 0.04);

    border: 1px solid ${themeGet('colors.primary_100')};
  }
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  padding: 0 ${themeGet('space.xs')}px;
  min-width: 130px;
  height: 48px;
  border-bottom: 1px solid ${themeGet('colors.primary_100')};
  background: ${({ isActive }) =>
    isActive ? themeGet('colors.primary_100') : themeGet('colors.white')};

  :last-child {
    border-bottom: none;
  }

  :hover {
    background: ${themeGet('colors.primary_100')};
  }
`;

function ListMenu({ options, isOpen, parentHeight, placement = 'bottomLeft' }) {
  const position = useMemo(() => {
    const result = { top: 0, bottom: 0, left: 0, right: 0 };

    switch (placement) {
      case 'bottomLeft':
        return { top: parentHeight, right: 0 };
      case 'topRight':
        return { bottom: parentHeight, left: 0 };
      default:
        return result;
    }
  }, [placement, parentHeight]);

  return (
    <Wrapper isOpen={isOpen} position={position}>
      <ul>
        {options.map((option) => (
          <Li isActive={option.props.isActive} key={option.key}>
            {option}
          </Li>
        ))}
      </ul>
    </Wrapper>
  );
}

export default ListMenu;
