import React from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';

const Wrapper = styled('svg').withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})`
  width: 18px;

  ${layout}
  ${space}
`;

function Card({ style }) {
  return (
    <Wrapper {...style} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2 6.50918H1.8V4.70918H16.2V6.50918ZM16.2 15.5092H1.8V10.1092H16.2V15.5092ZM16.2 2.90918H1.8C0.801 2.90918 0 3.71018 0 4.70918V15.5092C0 16.5033 0.805887 17.3092 1.8 17.3092H16.2C17.1941 17.3092 18 16.5033 18 15.5092V4.70918C18 3.71018 17.19 2.90918 16.2 2.90918Z"
        fill="currentColor"
      />
    </Wrapper>
  );
}

export default Card;
