import React from 'react';
import Head from 'next/head';
import styled from 'styled-components';
import { color, space, layout, border } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Body from 'theme/layouts/body';
import UserMenu from 'components/user-menu';

const Wrapper = styled.div`
  ${space}
  ${color}
  ${border}

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  & > .left {
    display: flex;
    align-items: center;
  }

  & > .centered {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: ${themeGet('space.s')}px 0;
  }
`;

const UserBar = styled.div`
  ${layout}
  width: 100%;
`;

function Header({ style, title, bodyColor, componentLeft, component, noWarning }) {
  return (
    <Wrapper {...style}>
      <Head>
        <title>{title}</title>
      </Head>
      <Body bg={bodyColor} />

      <div className="left">{componentLeft}</div>
      <div className="centered">{component}</div>
      <UserBar maxWidth={['236px', '236px', '236px', '320px']}>
        <UserMenu noWarning={noWarning} />
      </UserBar>
    </Wrapper>
  );
}

export default Header;
