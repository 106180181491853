import React from 'react';

function TimelineChart() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.63636 10.9546L2.04545 11.0119L5.78455 7.27282C5.63727 6.741 5.77636 6.13554 6.21 5.71009C6.84818 5.06373 7.87909 5.06373 8.51727 5.71009C8.95091 6.13554 9.09 6.741 8.94273 7.27282L11.0455 9.37554L11.4545 9.31827C11.6018 9.31827 11.7409 9.31827 11.8636 9.37554L14.7845 6.45463C14.7273 6.33191 14.7273 6.19282 14.7273 6.04554C14.7273 5.1418 15.4599 4.40918 16.3636 4.40918C17.2674 4.40918 18 5.1418 18 6.04554C18 6.94928 17.2674 7.68191 16.3636 7.68191C16.2164 7.68191 16.0773 7.68191 15.9545 7.62463L13.0336 10.5455C13.0909 10.6683 13.0909 10.8074 13.0909 10.9546C13.0909 11.8584 12.3583 12.591 11.4545 12.591C10.5508 12.591 9.81818 11.8584 9.81818 10.9546L9.87545 10.5455L7.77273 8.44282C7.51091 8.50009 7.21636 8.50009 6.95455 8.44282L3.21545 12.1819L3.27273 12.591C3.27273 13.4947 2.5401 14.2274 1.63636 14.2274C0.732625 14.2274 0 13.4947 0 12.591C0 11.6873 0.732625 10.9546 1.63636 10.9546Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default TimelineChart;
