import React from 'react';
import styled from 'styled-components';
import { flexbox, color, space } from 'styled-system';
import { useSelector } from 'react-redux';
import Body from 'theme/layouts/body';
import Header from 'theme/header';
import Card from 'theme/card';
import Text from 'theme/text';
import { TwoTone } from 'theme/logo';
import AlertRhombus from 'theme/icons/alert-rhombus';
import translations from 'translations';

const Wrapper = styled.div`
  ${flexbox}
  ${space}

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
`;

const WrapLogo = styled.div`
  ${space}
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapLoader = styled.div`
  ${color}
`;

function Disabled() {
  const { languageCode } = useSelector((state) => state.config);

  return (
    <Wrapper>
      <Body bg="primary_50" height="100%" />
      <Header
        bg="white"
        title={translations[languageCode]['Profile Title']}
        style={{
          bg: 'white',
          borderWidth: '0',
          borderBottomWidth: '1px',
          borderColor: 'primary_200',
          borderStyle: 'solid',
        }}
      />

      <WrapLogo my="xxl">
        <TwoTone height={48} />
      </WrapLogo>

      <Card width="100%" maxWidth="420px" bg="white" borderColor="primary_200" px="m" py="l">
        <Box>
          <WrapLoader color="primary_200">
            <AlertRhombus width="56" />
          </WrapLoader>

          <Text fontSize="h2" lineHeight="h2" fontWeight="bold" color="grey_600" mt="m">
            {translations[languageCode]['Account Disabled']}
          </Text>

          <Text
            fontSize="h3"
            lineHeight="h3"
            fontWeight="normal"
            color="grey_600"
            mt="xs"
            textAlign="center"
          >
            {translations[languageCode]['contact support']}
          </Text>
        </Box>
      </Card>
    </Wrapper>
  );
}

export default Disabled;
