import React from 'react';

function Logout() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M11.08 12.8171L13.67 10.2271H4V8.22705H13.67L11.08 5.63705L12.5 4.22705L17.5 9.22705L12.5 14.2271L11.08 12.8171ZM16 0.227051C17.1046 0.227051 18 1.12248 18 2.22705V6.89705L16 4.89705V2.22705H2V16.2271H16V13.5571L18 11.5571V16.2271C18 17.3316 17.1046 18.2271 16 18.2271H2C0.89 18.2271 0 17.3271 0 16.2271V2.22705C0 1.11705 0.89 0.227051 2 0.227051H16Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0.227051H18V18.2271H0V0.227051Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logout;
