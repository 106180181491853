import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { color, space, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Text from 'theme/text';
import Btn from 'theme/buttons/btn-user-menu';
import CloseIcon from 'theme/icons/close';

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(6, 119, 134, 0.6);
  z-index: 999;

  & > div {
    padding: ${themeGet('space.xxl')}px 0;

    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

const Content = styled.div`
  ${color}
  ${layout}

  border-radius: 12px;
  overflow: hidden;

  & > .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: ${themeGet('colors.white')};
    height: 86px;
    padding: 0 ${themeGet('space.s')}px;

    & > div:first-child {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  & > .body {
    display: flex;
    flex-direction: row;
    padding: ${themeGet('space.s')}px;

    ${space}

    & > div:first-child {
      margin-left: 0;
    }
  }

  & > .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: ${themeGet('space.m')}px;

    & > a {
      text-decoration: none;
      cursor: pointer;
      color: ${themeGet('colors.primary_400')};

      & > :hover {
        color: ${themeGet('colors.primary_300')};
      }
    }
  }
`;

function CustomModal({
  contentStyle,
  width,
  bgColor,
  style,
  isVisible,
  onClose,
  title,
  children,
  footer,
}) {
  return (
    <Wrapper {...style} isVisible={isVisible}>
      {isVisible && <GlobalStyle />}

      <div>
        <Content width={width || '800px'} bg={bgColor || 'primary_50'} {...contentStyle}>
          <div className="head">
            <div>
              <Text fontSize="h1" lineHeight="h1" fontWeight="bold" color="grey_600">
                {title}
              </Text>
            </div>
            <Btn
              type="button"
              style={{
                width: '38px',
                height: '38px',
                mr: 'xs',
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </Btn>
          </div>
          <div className="body">{children}</div>
          <div className="footer">{footer}</div>
        </Content>
      </div>
    </Wrapper>
  );
}

export default CustomModal;
