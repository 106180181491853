import React from 'react';

function Help() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.89954 0C4.93694 0 0.899536 4.0374 0.899536 9C0.899536 13.9626 4.93694 18 9.89954 18C14.8621 18 18.8995 13.9626 18.8995 9C18.8995 4.0374 14.8621 0 9.89954 0ZM10.7995 14.4H8.99954V12.6H10.7995V14.4ZM11.6779 10.0035C11.5015 10.1457 11.3314 10.2816 11.1964 10.4166C10.8292 10.7829 10.8004 11.1159 10.7995 11.1303V11.25H8.99954V11.0997C8.99954 10.9935 9.02564 10.0404 9.92294 9.1431C10.0984 8.9676 10.3162 8.7894 10.5448 8.604C11.2054 8.0685 11.6392 7.6779 11.6392 7.1397C11.6288 6.68504 11.4408 6.25252 11.1155 5.93471C10.7902 5.61689 10.3534 5.43901 9.89865 5.43912C9.44386 5.43924 9.00719 5.61735 8.68206 5.93534C8.35692 6.25332 8.16916 6.68593 8.15894 7.1406H6.35894C6.35894 5.1885 7.94744 3.6 9.89954 3.6C11.8516 3.6 13.4401 5.1885 13.4401 7.1406C13.4401 8.5779 12.379 9.4356 11.6779 10.0035Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Help;
