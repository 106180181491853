/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'theme/content-loader';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

const WrapAvatar = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarPicture = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-image: url(${({ picture }) => picture});
  background-position: 0% 0%;
  background-size: cover;
  background-repeat: no-repeat;
`;

function CustomAvatar({ data }) {
  return (
    <Wrapper>
      <WrapAvatar>
        {data.avatar ? (
          <AvatarPicture picture={data.avatar} />
        ) : (
          <ContentLoader
            width={48}
            height={48}
            lines={[
              {
                x: 0,
                y: 0,
                width: 48,
                height: 48,
              },
            ]}
          />
        )}
      </WrapAvatar>
    </Wrapper>
  );
}

export default CustomAvatar;
