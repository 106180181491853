import React from 'react';

function Feed() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M16 2.40918V4.40918H12V2.40918H16ZM6 2.40918V8.40918H2V2.40918H6ZM16 10.4092V16.4092H12V10.4092H16ZM6 14.4092V16.4092H2V14.4092H6ZM18 0.40918H10V6.40918H18V0.40918ZM8 0.40918H0V10.4092H8V0.40918ZM18 8.40918H10V18.4092H18V8.40918ZM8 12.4092H0V18.4092H8V12.4092Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0.40918H18V18.4092H0V0.40918Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Feed;
