/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import configSelector from './config';

export default createSelector(
  (state) => state.warning.payload,
  configSelector,
  (payload, { languageCode }) =>
    payload.map((warning) => ({
      key: warning.id,
      message: warning.data.msg[languageCode],
      level: warning.data.level,
      panel_class: warning.data.panel_class,
    })),
);
