import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { setWarningVisible } from 'redux-api/action/warning';
import { setLanguage } from 'redux-api/action/lang';
import { signoutRequest, setUserImpersonation } from 'redux-api/action/auth';
import selectProfile from 'redux-api/reselect/profile';
import selectWarning from 'redux-api/reselect/warning';
import { space } from 'styled-system';
import styled from 'styled-components';
import Dropdown from 'theme/dropdown';
import DropdownItem from 'theme/dropdown/item';
import Btn from 'theme/buttons/btn-user-menu';
import Web from 'theme/icons/web';
// import Notification from 'theme/icons/notification';
import Alert from 'theme/icons/alert';
import { CircleFlag } from 'react-circle-flags';
import Logout from 'theme/icons/logout';
import User from 'theme/icons/user';
import BtnBorder from 'theme/buttons/btn-border';
import Input from 'theme/input';
import Modal from 'theme/modal';
import Avatar from 'theme/avatar';
import translations from 'translations';

const Wrapper = styled.div`
  ${space}

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const WrapBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WrapItem = styled.a`
  width: 100%;
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
`;

const ModalBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 450px;
  }
`;

const StyledCircleFlag = styled(CircleFlag)`
  width: 22px;
  height: 22px;
`;

function UserMenu() {
  const { adminRequestedStreetlibInternalId } = useSelector((state) => state.auth);

  const [impersonationModal, setImpersonationModal] = useState(false);
  const [userImpersonationState, setUserImpersonationState] = useState(
    adminRequestedStreetlibInternalId,
  );

  const dispatch = useDispatch();

  const { isVisible } = useSelector((state) => state.warning);
  const warnings = useSelector(selectWarning);
  const { payload, isAdmin, isEnabled } = useSelector(selectProfile);
  const { languageCode } = useSelector((state) => state.config);

  const handleSendUserImpersonation = useCallback(() => {
    dispatch(setUserImpersonation(userImpersonationState));
    setImpersonationModal(false);
  }, [dispatch, userImpersonationState]);

  const handleWarning = useCallback(() => {
    dispatch(setWarningVisible(true));
  }, [dispatch]);

  const setItaLang = useCallback(() => {
    dispatch(setLanguage({ language: 'it' }));
  }, [dispatch]);

  const setEngLang = useCallback(() => {
    dispatch(setLanguage({ language: 'en' }));
  }, [dispatch]);

  const setPorLang = useCallback(() => {
    dispatch(setLanguage({ language: 'pt' }));
  }, [dispatch]);

  const setGerLang = useCallback(() => {
    dispatch(setLanguage({ language: 'de' }));
  }, [dispatch]);

  const handleSignout = useCallback(() => {
    dispatch(signoutRequest());
  }, [dispatch]);

  const handleImpersonation = useCallback(() => {
    setImpersonationModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setImpersonationModal(false);
  }, []);

  const userDropdownOptions = useMemo(() => {
    if (!isEnabled) {
      return [
        <DropdownItem
          key="logout"
          icon={<Logout />}
          label={translations[languageCode].logout}
          onClick={handleSignout}
        />,
      ];
    }

    const options = [
      <Link href="/profile">
        <WrapItem title={translations[languageCode].profile} key="profile">
          <DropdownItem icon={<User />} label={translations[languageCode].profile} />
        </WrapItem>
      </Link>,
      <DropdownItem
        key="logout"
        icon={<Logout />}
        label={translations[languageCode].logout}
        onClick={handleSignout}
      />,
    ];

    if (isAdmin) {
      options.unshift(
        <DropdownItem
          key="user-impersonation"
          icon={<User />}
          label="Impersonation"
          onClick={handleImpersonation}
        />,
      );
    }

    return options;
  }, [handleSignout, handleImpersonation, isAdmin, isEnabled, languageCode]);

  const langOptions = useCallback(() => {
    const options = [
      <DropdownItem
        key="ita"
        icon={<StyledCircleFlag countryCode="it" />}
        label="Italiano"
        isActive={languageCode === 'it'}
        onClick={setItaLang}
      />,
      <DropdownItem
        key="eng"
        icon={<StyledCircleFlag countryCode="us" />}
        label="English"
        isActive={languageCode === 'en'}
        onClick={setEngLang}
      />,
      <DropdownItem
        key="ger"
        icon={<StyledCircleFlag countryCode="de" />}
        label="Deutsch"
        isActive={languageCode === 'de'}
        onClick={setGerLang}
      />,
      <DropdownItem
        key="por"
        icon={<StyledCircleFlag countryCode="pt" />}
        label="Português"
        isActive={languageCode === 'pt'}
        onClick={setPorLang}
      />,
    ];

    return options;
  }, [languageCode, setEngLang, setGerLang, setItaLang, setPorLang]);

  return (
    <>
      <Wrapper pr="m" pb="s" pt="s">
        {!isVisible && warnings.length > 0 && (
          <Btn
            title="warning"
            type="button"
            style={{
              width: ['38px', '38px', '38px', '56px'],
              height: ['38px', '38px', '38px', '56px'],
              mr: ['xs', 'xs', 'xs', 's'],
            }}
            iconColor="warning"
            onClick={handleWarning}
          >
            <Alert
              style={{
                width: ['18px', '18px', '18px', '26px'],
              }}
            />
          </Btn>
        )}

        {/*  {isEnabled && (
          <Btn
            type="button"
            style={{
              width: ['38px', '38px', '38px', '56px'],
              height: ['38px', '38px', '38px', '56px'],
              mr: ['xs', 'xs', 'xs', 's'],
            }}
            onClick={() => null}
          >
            <Notification
              style={{
                width: ['16px', '16px', '16px', '26px'],
              }}
            />
          </Btn>
        )} */}

        <Dropdown style={{ position: 'relative' }} options={langOptions()}>
          <Btn
            type="button"
            style={{
              width: ['38px', '38px', '38px', '56px'],
              height: ['38px', '38px', '38px', '56px'],
              mr: ['xs', 'xs', 'xs', 's'],
            }}
            title="change language"
          >
            <Web
              style={{
                width: ['18px', '18px', '18px', '26px'],
              }}
            />
          </Btn>
        </Dropdown>

        <Dropdown style={{ position: 'relative' }} options={userDropdownOptions}>
          <Btn
            type="button"
            style={{
              width: ['38px', '38px', '38px', '56px'],
              height: ['38px', '38px', '38px', '56px'],
            }}
            title="user options"
          >
            <Avatar data={payload} />
          </Btn>
        </Dropdown>
      </Wrapper>
      <Modal isVisible={impersonationModal} onClose={handleCloseModal} title="User Impersonation">
        <ModalBody>
          <div>
            <Input
              meta={{}}
              input={{
                onChange: (e) => setUserImpersonationState(e.target.value),
                value: userImpersonationState,
              }}
            />
            <WrapBtn>
              <BtnBorder
                onClick={handleSendUserImpersonation}
                style={{
                  width: '100%',
                  maxWidth: '140px',
                  px: 0,
                  mb: 'l',
                }}
                type="button"
                label={translations[languageCode].send}
              />
            </WrapBtn>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default UserMenu;
