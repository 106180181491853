import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Text from 'theme/text';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ isTitle }) => (isTitle ? 'none' : 'pointer')};

  & > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: ${themeGet('space.xs')}px;
    color: ${themeGet('colors.primary_300')};
  }
`;

function Item({ onClick, icon, label, isTitle }) {
  if (isTitle) {
    return (
      <Wrapper isTitle={isTitle} onClick={onClick}>
        <Text fontSize="h4" lineHeight="h4" fontWeight="medium" color="grey_600">
          {label}
        </Text>
      </Wrapper>
    );
  }

  return (
    <Wrapper onClick={onClick}>
      {!!icon && <span>{icon}</span>}
      <Text fontSize="display" lineHeight="display" fontWeight="medium" color="grey_400">
        {label}
      </Text>
    </Wrapper>
  );
}

export default Item;
