import React from 'react';
import { layout, space, color, typography, border } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import styled, { keyframes } from 'styled-components';
import Loader from 'theme/icons/loader';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Button = styled.button`
  height: 56px;
  min-width: 140px;
  background: transparent;
  padding: 0;
  outline: 0;

  & > span {
    background: ${themeGet('colors.white')};

    padding: 0 ${themeGet('space.s')}px;

    ${color}
    ${typography}

    border: ${props => !props.active ? '2px solid' : 'none'};

    border-radius: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    border-color: ${themeGet('colors.primary_300')};
  }

  &:focus {
    border-color: ${themeGet('colors.primary_600')};
    background: transparent;
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
  }

  ${layout}
  ${space}
  ${border}
`;

const WrapLoader = styled.div`
  margin-left: ${themeGet('space.xs')}px;
  width: 18px;
  height: 18px;
  animation: ${rotate} 2s linear infinite;
`;

function Btn({ isWaiting, style, title, label, type, onClick, disabled, active, ...rest }) {
  return (
    <Button
      title={title || label}
      aria-label={title || label}
      fontSize="h4"
      lineHeight="h4"
      fontWeight="bold"
      color="grey_400"
      px="l"
      {...style}
      type={type}
      disabled={disabled || isWaiting}
      onClick={onClick}
      active={active}
      {...rest}
    >
      <span>
        {label}
        {isWaiting && (
          <WrapLoader>
            <Loader width={18} />
          </WrapLoader>
        )}
      </span>
    </Button>
  );
}

export default Btn;
