import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import ContentLoader from 'react-content-loader';

function CustomContentLoader({ width, height, speed, backgroundColor, foregroundColor, lines }) {
  const theme = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={speed || 2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={backgroundColor || themeGet(`colors.primary_100`)({ theme })}
      foregroundColor={foregroundColor || themeGet(`colors.primary_50`)({ theme })}
    >
      {lines.map((line) => (
        <rect
          key={`${line.x}-${line.y}`}
          x={line.x}
          y={line.y}
          rx={line.rx}
          ry={line.ry}
          width={line.width}
          height={line.height}
        />
      ))}
    </ContentLoader>
  );
}

export default CustomContentLoader;
