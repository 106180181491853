import React from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';

const Wrapper = styled('svg').withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})`
  width: 18px;

  ${layout}
  ${space}
`;

function Web({ style }) {
  return (
    <Wrapper {...style} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.924 11.7473C12.996 11.1533 13.05 10.5593 13.05 9.94727C13.05 9.33527 12.996 8.74127 12.924 8.14727H15.966C16.11 8.72327 16.2 9.32627 16.2 9.94727C16.2 10.5683 16.11 11.1713 15.966 11.7473M11.331 16.7513C11.871 15.7523 12.285 14.6723 12.573 13.5473H15.228C14.364 15.0323 12.987 16.1843 11.331 16.7513ZM11.106 11.7473H6.894C6.804 11.1533 6.75 10.5593 6.75 9.94727C6.75 9.33527 6.804 8.73227 6.894 8.14727H11.106C11.187 8.73227 11.25 9.33527 11.25 9.94727C11.25 10.5593 11.187 11.1533 11.106 11.7473ZM9 17.1113C8.253 16.0313 7.65 14.8343 7.281 13.5473H10.719C10.35 14.8343 9.747 16.0313 9 17.1113ZM5.4 6.34727H2.772C3.627 4.85327 5.013 3.70127 6.66 3.14327C6.12 4.14227 5.715 5.22227 5.4 6.34727ZM2.772 13.5473H5.4C5.715 14.6723 6.12 15.7523 6.66 16.7513C5.013 16.1843 3.627 15.0323 2.772 13.5473ZM2.034 11.7473C1.89 11.1713 1.8 10.5683 1.8 9.94727C1.8 9.32627 1.89 8.72327 2.034 8.14727H5.076C5.004 8.74127 4.95 9.33527 4.95 9.94727C4.95 10.5593 5.004 11.1533 5.076 11.7473M9 2.77427C9.747 3.85427 10.35 5.06027 10.719 6.34727H7.281C7.65 5.06027 8.253 3.85427 9 2.77427ZM15.228 6.34727H12.573C12.285 5.22227 11.871 4.14227 11.331 3.14327C12.987 3.71027 14.364 4.85327 15.228 6.34727ZM9 0.947266C4.023 0.947266 0 4.99727 0 9.94727C0 12.3342 0.948212 14.6234 2.63604 16.3112C4.32387 17.9991 6.61305 18.9473 9 18.9473C13.9706 18.9473 18 14.9178 18 9.94727C18 7.56032 17.0518 5.27113 15.364 3.5833C13.6761 1.89548 11.3869 0.947266 9 0.947266Z"
        fill="currentColor"
      />
    </Wrapper>
  );
}

export default Web;
