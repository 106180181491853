import React from 'react';
import styled from 'styled-components';
import { space, color, border, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Text from 'theme/text';

const Wrapper = styled.div`
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 72px;

    ${color}
    ${space}
    ${border}
  }
`;

const Line = styled.div`
  ${color}
  ${space}
  ${layout}

  display: flex;
  position: relative;
  height: 8px;
  border-radius: 3px;

  :after {
    content: ' ';
    position: absolute;
    top: 0px;
    background-color: ${({ colorLine }) => themeGet(`colors.${colorLine}`)};
    height: 8px;
    width: ${({ percent }) => percent}%;
    border-radius: 3px;

    ${border}
  }
`;

function Onboarding({ label, percent, disabled }) {
  return (
    <Wrapper
      bg={disabled ? 'primary_400' : 'primary_600'}
      m={['0px', '0px', '0px', 'xs']}
      borderRadius={['0px', '0px', '0px', '4px']}
    >
      <div>
        {!disabled && (
          <Line
            bg="primary_500"
            percent={percent}
            colorLine="warning"
            width={['64px', '64px', '64px', '84px']}
          />
        )}
        <Text
          pt={disabled ? '0' : 'xxs'}
          fontSize="pSmall"
          lineHeights="pSmall"
          fontWeight="bold"
          color="white"
        >
          {label}
        </Text>
      </div>
    </Wrapper>
  );
}

export default Onboarding;
