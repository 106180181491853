import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { position } from 'styled-system';
import ListMenu from './list-menu';

const Wrapper = styled.div`
  ${position}
`;

const WrapButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function CustomDropdown({ options, children, style, placement }) {
  const [isOpen, setOpen] = useState(false);

  const ref = useRef(null);

  const handleClick = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <Wrapper
      type="button"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={handleClick}
      {...style}
    >
      <WrapButton ref={ref}>{children}</WrapButton>

      <ListMenu
        placement={placement}
        options={options}
        isOpen={isOpen}
        parentHeight={ref.current ? ref.current.offsetHeight : 0}
      />
    </Wrapper>
  );
}

export default CustomDropdown;
