import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';

// RETURN callback if is clicked outside
// eslint-disable-next-line import/prefer-default-export
export const useOutsideClick = (ref, callback, extraClass) => {
  const handleClick = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        callback();
      }

      const elements = document.getElementsByClassName(extraClass);

      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i] && elements[i].contains(e.target)) {
          return;
        }
      }

      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [callback, ref, extraClass],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleClick, true);
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('keydown', handleClick, true);
      document.removeEventListener('click', handleClick, true);
    };
  });
};

export const useDimensions = () => {
  const intervalId = useRef(null);

  const getSize = useCallback(() => {
    const isClient = typeof window === 'object';

    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }, []);

  const [windowSize, setWindowSize] = useState(getSize());

  const handleResize = useCallback(() => {
    const delay = 250;

    clearTimeout(intervalId.current);

    intervalId.current = setTimeout(() => {
      const size = getSize();

      if (size) {
        setWindowSize(size);
      }
    }, delay);
  }, [intervalId, getSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export const useNativePadding = () => {
  const [nativePadding, setNativePadding] = useState('0px');

  useEffect(() => {
    const isServer = typeof window === 'undefined';

    if (!isServer && window.ReactNativeWebView) {
      setNativePadding('36px');
    }
  }, []);

  return nativePadding;
};

export const useBornOnMaxDate = () => {
  const date = useMemo(() => {
    const today = moment();

    return today.subtract(18, 'year');
  }, []);

  return date;
};
