/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { space } from 'styled-system';
import { useNativePadding } from 'hooks';
import Menu from 'components/menu';

const Wrapper = styled.div`
  ${space}
`;

const page = ({ children }) => {
  const paddingTop = useNativePadding();
  const { pathname } = useRouter();

  return (
    <Wrapper
      ml={
        pathname !== '/profile' &&
        pathname !== '/disabled' &&
        pathname !== '/services' &&
        pathname !== '/enable_service'
          ? ['96px', '96px', '96px', '165px']
          : 0
      }
      pt={paddingTop}
    >
      {pathname !== '/profile' &&
        pathname !== '/disabled' &&
        pathname !== '/services' &&
        pathname !== '/enable_service' && <Menu />}
      {children}
    </Wrapper>
  );
};

export default page;
