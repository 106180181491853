import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { flexbox, typography, color, space, margin } from 'styled-system';
import { useSelector, useDispatch } from 'react-redux';
import Body from 'theme/layouts/body';
import Card from 'theme/card';
import Logo from 'theme/logo';
import Loader from 'theme/icons/loader';
import translations from 'translations';
import ButtonBorder from 'theme/buttons/btn-border';
import Link from 'next/link';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  ${flexbox}
  ${space}

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
`;

const Text = styled.p`
  ${typography}
  ${color}
  ${space}
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapLoader = styled.div`
  ${color}

  width: 40px;
  height: 40px;
  animation: ${rotate} 2s linear infinite;
`;

function LoaderComp({ authState }) {
  const dispatch = useDispatch();
  const { languageCode } = useSelector((state) => state.config);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let timeOut;
    window.addEventListener('load', () => {
      timeOut = setTimeout(() => {
        setLoaded(true);
      }, 10000); // Wait 10 seconds before showing the button
    });

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const handleRefresh = () => {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });

    const timestamp = new Date().getTime();
    const url = window.location.href;
    const urlWithTimestamp = `${url}?timestamp=${timestamp}`;

    // Esegui la ricarica forzata
    window.location.href = urlWithTimestamp;
  };

  const currentDomain = typeof window !== 'undefined' ? window.location.hostname : '';

  return (
    <Wrapper justifyContent={['space-between', 'space-between', 'flex-start']} px="l">
      <Body bg="primary_400" />
      {currentDomain.match(/bookrix.de/i) ? (
        <div style={{ margin: '20px' }}>
          <img src="/images/bookrix_logo_ext.png" width="300" height={87} alt="Logo BookRix" />
        </div>
      ) : (
        <Logo
          height={48}
          style={{
            color: 'white',
            height: '48px',
            my: 'xxl',
          }}
        />
      )}
      <Card width="100%" maxWidth="420px" bg="white" border="none" px="m" py="l" my="xxl">
        <Box>
          <WrapLoader color="primary_400">
            <Loader />
          </WrapLoader>
          <Text fontSize="h4" lineHeight="h4" fontWeight="bold" color="grey_600" mt="s">
            {translations[languageCode]['wait a few seconds']}
          </Text>
          <Text fontSize="pSmall" lineHeight="pSmall" fontWeight="normal" color="grey_600" mt="s">
            {translations[languageCode]['Do you want to switch user?']}{' '}
            <Link href={currentDomain.match(/bookrix.de/i) ? `https:${process.env.SIGNOUT_URI_DE}` : `https:${process.env.SIGNOUT_URI}`}>
              {translations[languageCode]['Sign Out']}
            </Link>
          </Text>
          {loaded && (
            <>
              <ButtonBorder
                style={{
                  p: 2,
                  mt: '20px',
                  color: 'primary_300',
                }}
                onClick={handleRefresh}
                label={translations[languageCode]['Reload browser cache']}
              />
            </>
          )}
        </Box>
      </Card>
    </Wrapper>
  );
}

export default LoaderComp;
