/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import { layout, space, typography } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { Input } from 'antd';
import CheckCircle from 'theme/icons/check-circle';
import Alert from 'theme/icons/alert';
import Loader from 'theme/icons/loader';
import InputError from 'theme/input-error';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  ${layout}
  ${space}
  ${typography}

  outline: 0;

  .ant-input {
    appearance: none;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    color: ${themeGet('colors.grey_400')};
    font-size: 16px;
    line-height: 1.5715;
    background-color: ${themeGet('colors.white')};
    background-image: none;
    box-shadow: none;
    transition: all 0.3s;
    width: 100%;
    height: 38px;
    border: 2px solid;
    border-color: ${({ meta }) =>
      meta.error && meta.touched ? themeGet('colors.red_400') : themeGet('colors.primary_100')};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 ${themeGet('space.s')}px;
    padding-right: ${({ addonAfter }) =>
      addonAfter ? themeGet('space.l') : themeGet('space.s')}px;

    :hover {
      border-color: ${themeGet('colors.primary_200')};
    }

    :focus {
      border-color: ${themeGet('colors.primary_500')};
    }

    :disabled {
      opacity: 0.5;
    }

    :placeholder-shown {
      text-overflow: ellipsis;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input-group {
    position: relative;
  }

  .ant-input-suffix {
    position: absolute;
    right: ${themeGet('space.s')}px;
    top: 0px;
    bottom: 0px;

    .ant-input-password-icon {
      color: ${themeGet('colors.grey_600')};

      & > svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .ant-input-group-addon {
    position: absolute;
    right: ${themeGet('space.s')}px;
    top: 0px;
    bottom: 0px;
    color: ${({ meta }) =>
      !meta.touched
        ? themeGet('colors.grey_600')
        : meta.error
        ? themeGet('colors.red_400')
        : themeGet('colors.primary_400')};
  }
`;

const WrapLoader = styled.div`
  width: 18px;
  height: 18px;
  animation: ${rotate} 2s linear infinite;
`;

function CustomInput({
  className,
  noError,
  addonAfter,
  autoComplete,
  disabled,
  input,
  meta,
  placeholder,
  style,
  isWaiting,
  languageCode,
}) {
  const inputIcon = useMemo(() => {
    if (!addonAfter) return '';

    if (isWaiting) {
      return (
        <WrapLoader>
          <Loader width={18} />
        </WrapLoader>
      );
    }

    if (!meta.touched) return '';

    return meta.error && meta.touched ? <Alert /> : <CheckCircle />;
  }, [addonAfter, isWaiting, meta]);

  return (
    <Wrapper
      addonAfter={addonAfter}
      fontSize="display"
      lineHeight="display"
      fontWeight="normal"
      meta={meta}
      {...style}
      className={className}
    >
      {input.type === 'password' ? (
        <Input.Password
          {...input}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
        />
      ) : (
        <Input
          {...input}
          autoComplete={autoComplete}
          placeholder={placeholder}
          disabled={disabled}
          addonAfter={inputIcon}
        />
      )}
      {!noError && (
        <InputError value={input.value} meta={meta} languageCode={languageCode || 'en'} />
      )}
    </Wrapper>
  );
}

export default CustomInput;
