import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signoutRequest } from 'redux-api/action/auth';
import Btn from 'theme/buttons/btn-menu';
import Logout from 'theme/icons/logout';
import Text from 'theme/text';
import translations from 'translations';

function BtnLogout() {
  const dispatch = useDispatch();

  const { languageCode } = useSelector((state) => state.config);

  const handleSignout = useCallback(() => {
    dispatch(signoutRequest());
  }, [dispatch]);

  return (
    <Btn
      title={translations[languageCode].logout}
      type="button"
      onClick={handleSignout}
      style={{
        width: ['76px', '76px', '76px', '100px'],
        height: ['50px', '50px', '50px', '64px'],
        mb: 'm',
      }}
    >
      <Logout />
      <Text
        fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
        lineHeights={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
        fontWeight="normal"
        color="inherit"
      >
        {translations[languageCode].logout}
      </Text>
    </Btn>
  );
}

export default BtnLogout;
