import React, { useContext } from 'react';
import { layout, space, color, typography } from 'styled-system';
import styled, { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Badge } from 'antd';

const Button = styled.button`
  position: relative;
  padding: 0;
  background: transparent;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  & > span.children {
    overflow: hidden;
    background: ${themeGet('colors.primary_50')};
    border-radius: 12px;

    ${color}
    ${typography}

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > svg {
      path {
        fill: ${({ iconColor }) => iconColor || themeGet('colors.primary_300')};
      }
    }
  }

  &:hover {
    background: transparent;
    outline: 0;

    & > span.children {
      background: ${themeGet('colors.primary_100')};
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  & > .ant-badge {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: -9px;
    right: 0;
    display: inline-block;
    color: unset;
    line-height: 1;

    & > .ant-badge-status-dot {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      vertical-align: middle;
      border-radius: 50%;
    }

    & > .ant-badge-status-processing {
      position: relative;
      background-color: ${themeGet('colors.primary_400')};
    }

    & > .ant-badge-status-processing::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid ${themeGet('colors.primary_400')};
      border-radius: 50%;
      animation: antStatusProcessing 1.2s infinite ease-in-out;
      content: '';
    }
  }

  & > .ant-badge-status {
    line-height: inherit;
    vertical-align: baseline;
  }

  ${layout}
  ${space}
`;

function BtnMenu({ title, iconColor, addBadge, style, children, type, onClick, disabled }) {
  const theme = useContext(ThemeContext);

  return (
    <Button
      iconColor={themeGet(`colors.${iconColor}`)({ theme })}
      {...style}
      type={type}
      disabled={disabled}
      onClick={onClick}
      title={title}
      aria-label={title}
    >
      {addBadge && <Badge status="processing" />}
      <span className="children">{children}</span>
    </Button>
  );
}

export default BtnMenu;
