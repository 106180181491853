import React from 'react';

function ListUl({ width = '18' }) {
  return (
    <svg width={width} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3.26758H3V5.26758H1V3.26758ZM1 8.26758H3V10.2676H1V8.26758ZM1 13.2676H3V15.2676H1V13.2676ZM17 5.26758V3.26758H5.023V5.26758H15.8H17ZM5 8.26758H17V10.2676H5V8.26758ZM5 13.2676H17V15.2676H5V13.2676Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ListUl;
